<!--
 * @Author: xr
 * @Date: 2022-02-16 17:14:14
 * @LastEditors: xr
 * @LastEditTime: 2022-03-11 09:57:32
 * @FilePath: \postStation-office\src\views\us.vue
-->
<template >
  <div class="main">
    <div class="main_box">
      <a-row type="flex" :gutter="20" justify="space-between" style="padding:0px 10px 10px;">
        <a-col :span="7" style="padding-left: 0px">
          <find-demand class="find-item" title="找项目" :technicalProjectContent="technicalProjectContent" />
          <find-demand class="find-demand" title="找需求" :technicalProjectContent="technicalProjectContent"/>
        </a-col>
        <a-col :span="17" style="min-height:62vh">
          <a-row style="padding-top:10px;min-height: 75.9vh;" class="sub-component-background-color">
            <article class="introduction">
              <strong><h3>{{header}}</h3></strong>
                <!-- 主站(introductionStation) 子站(introductionStation)-->
                <a-empty v-show="!information.introduction && !information.introductionStation" class="empty-data"/>
                <p v-show="information.introduction || information.introductionStation" v-html="information.introductionStation || information.introduction"/>
            </article>
          </a-row>
        </a-col>
      </a-row>
      <!-- 友情链接 -->
      <link-box />
    </div>
  </div>
</template>
<script>
import linkBox from '@comp/layout/link'
import FindDemand from '@comp/findDemand'
import { getDict, information, introductionToSubStation } from '@/api/index'
import { Station } from '@/mixins/station'

export default {
  name: 'Us',
  mixins: [Station],
  components: {
    linkBox,
    FindDemand
  },
  data() {
    return {
      header: '浙大校友求是驿站简介',
      technicalProjectContent: [],
      information: {}
    }
  },
  methods: {

  },
  created() {
    // 判断是否为子站
    this.getStationId()
    getDict('application_field').then(res => {
      this.technicalProjectContent = res.result
    })
    // 判断是否为子站
    if (!this.stationId) {
      // 总站信息
      information().then(res => {
        this.information = res.result[0] ? res.result[0] : ''
      })
    } else {
      // 子站简介
      introductionToSubStation({ stationId: this.stationId }).then(res => {
        this.information = res.result
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.introduction{
  &>p{
    font-family: '宋体';
    font-size: 16px;
  }
  h3{
    padding: 10px 0px;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    font-family: '宋体';
  }
  .text{
    text-indent: 24px;
  }
}
@import "../assets/css/newsInformation.css";
</style>
